<script setup lang="ts">
import utils from './utils.ts'

const props = withDefaults(defineProps<{
  showIntrobox?: boolean
  showWhyChoose?: boolean
}>(), { showIntrobox: true, showWhyChoose: true })

onMounted(() => {
})
</script>

<template>
  <div>
    <div v-if="showIntrobox" class="introbox-new">
      <ul class="introbox-content">
        <li class="introbox-item">
          <div class="box">
            <div>
              <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/intro-03.png">
            </div>
            <div>
              <h4>{{ utils.tc('b_cre_title_new_1') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_1') }}</p>
            </div>
          </div>
        </li>
        <li class="introbox-item">
          <div class="box">
            <div style="margin-left: 15px">
              <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/intro-02.png">
            </div>
            <div>
              <h4>{{ utils.tc('b_cre_title_new_2') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_2') }}</p>
            </div>
          </div>
        </li>
        <li class="introbox-item">
          <div class="box">
            <div>
              <img src="https://file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/intro-01.png">
            </div>
            <div>
              <h4>{{ utils.tc('b_cre_title_new_3') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_3') }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="props.showWhyChoose" class="why-choose">
      <p class="why-choose-title">
        {{ utils.tc('b_credence_title_new') }}
      </p>
      <div class="why-choose-content">
        <div class="why-item item-left">
          <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/why-book-01.jpg">
          <div class="detail-box">
            <b />
            <h4>{{ utils.tc('b_cre_title_new_4') }}</h4>
            <p>{{ utils.tc('b_cre_content_new_4') }}</p>
          </div>
        </div>
        <div class="why-item item-middle">
          <div class="item-box-top">
            <div class="describe-box">
              <b />
              <h4>{{ utils.tc('b_cre_title_new_5') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_5') }}</p>
            </div>
          </div>
          <div class="item-box-bottom">
            <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/why-book-03.jpg">
            <div class="describe-box">
              <h4>{{ utils.tc('b_cre_title_new_6') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_6') }}</p>
            </div>
          </div>
        </div>
        <div class="why-item item-right">
          <div class="item-box-top">
            <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/why-book-02.jpg">
            <div class="describe-box">
              <h4>{{ utils.tc('b_cre_title_new_7') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_7') }}</p>
            </div>
          </div>
          <div class="item-box-bottom">
            <div class="describe-box">
              <b />
              <h4>{{ utils.tc('b_cre_title_new_8') }}</h4>
              <p>{{ utils.tc('b_cre_content_new_8') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.introbox-new {
  max-width: 1920px;
  min-width: 1190px;
  margin: 0 auto;
  background: #fbfbfb;
}

.introbox-content {
  padding: 30px 0;
  width: 1190px;
  margin: 0 auto;
  display: flex
}

.introbox-item {
  flex: 1
}

.introbox-item .box {
  display: flex
}

.introbox-item .box img {
  margin-right: 19px;
  max-width: none;
}

.introbox-item .box h4 {
  font-size: 18px;
  color: #333;
  font-weight: 700;
  margin-top: -5px;
  padding-bottom: 10px
}

.introbox-item .box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #888;
  padding-right: 35px
}

.introbox-item:last-child .box img {
  margin: 0 19px 0 30px
}

.introbox-item:last-child .box p {
  padding: 0
}

.why-choose {
  width: 1190px;
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 15px;
}

.why-choose .why-choose-title {
  padding: 30px;
  font-weight: 500;
  font-size: 48px;
  color: #333;
  text-align: center
}

.why-choose .why-choose-content {
  height: 530px;
  display: flex
}

.why-choose .why-item {
  flex: 1
}

.why-choose .item-left {
  position: relative;
  margin-right: 20px
}

.why-choose .item-left .detail-box {
  position: absolute;
  padding: 40px 30px 30px;
  width: 300px;
  background: var(--Brand-Green);
  left: -21px;
  bottom: 30px
}

.item-middle .item-box-top .describe-box b,.item-right .item-box-bottom .describe-box b,.why-choose .item-left .detail-box b {
  display: block;
  width: 20px;
  height: 4px;
  background: #fff;
  border-radius: 3px
}

.item-middle .item-box-top .describe-box h4,.item-right .item-box-bottom .describe-box h4,.why-choose .item-left .detail-box h4 {
  font-size: 28px;
  padding: 20px 0;
  line-height: 24px;
  color: #fff
}

.item-middle .item-box-top .describe-box p,.item-right .item-box-bottom .describe-box p,.why-choose .item-left .detail-box p {
  margin-top: -5px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 24px
}

.why-choose .item-middle {
  margin-right: 20px
}

.why-choose .item-middle .item-box-bottom,.why-choose .item-right .item-box-top {
  position: relative
}

.why-choose .item-middle .item-box-bottom .describe-box {
  position: absolute;
  padding: 30px;
  bottom: 0;
  left: 0
}

.why-choose .item-right .item-box-top .describe-box {
  position: absolute;
  padding: 30px;
  bottom: 0;
  left: 0
}

.item-middle .item-box-bottom .describe-box h4,.item-right .item-box-top .describe-box h4 {
  color: #fff;
  font-size: 28px;
  line-height: 24px;
  padding: 15px 0
}

.item-middle .item-box-bottom .describe-box p,.item-right .item-box-top .describe-box p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px
}

.why-choose .item-box-bottom,.why-choose .item-box-top {
  height: 255px;
  background: var(--Brand-Green);
  margin-bottom: 20px;
  position: relative
}

.item-middle .item-box-top .describe-box,.item-right .item-box-bottom .describe-box {
  position: absolute;
  padding: 30px;
  bottom: 0;
  left: 0
}
</style>
