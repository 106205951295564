<script setup lang="ts">
import utils from './utils.ts'

onMounted(() => {
})
</script>

<template>
  <div>
    <div class="userrevbox">
      <div class="userbox">
        <h3>{{ utils.tc('b_user_title') }}</h3>
        <h4><span><b>90%</b></span> {{ utils.tc('b_user_subtitle_1') }}</h4>
        <h4><span><b>100%</b></span> {{ utils.tc('b_user_subtitle_2') }}</h4>
        <ul>
          <li>
            <div>
              <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/user_img_zhtw_1.png">
              <span>{{ utils.tc('b_user1_name') }}</span>
            </div>
            <p>{{ utils.tc('b_user1_said') }}</p>
          </li>
          <li>
            <div>
              <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/user_img_zhtw_2.png">
              <span>{{ utils.tc('b_user2_name') }}</span>
            </div>
            <p>{{ utils.tc('b_user2_said') }}</p>
          </li>
          <li>
            <div>
              <img src="//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/user_img_zhtw_3.png">
              <span>{{ utils.tc('b_user3_name') }}</span>
            </div>
            <p>{{ utils.tc('b_user3_said') }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.userrevbox {
  min-width: 1190px;
  max-width: 1920px;
  margin: 0 auto
}

.userrevbox .userbox {
  width: 1190px;
  margin: 0 auto;
  padding: 30px 0 75px 0
}

.userrevbox .userbox>h3 {
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: center
}

.userrevbox .userbox>h4 {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  text-align: center;
  line-height: 32px
}

.userrevbox .userbox>h4>span {
  z-index: 0;
  position: relative
}

.userrevbox .userbox>h4>span>b {
  font-style: normal;
  font-weight: 500;
  color: #333
}

.userrevbox .userbox>h4>span:after {
  z-index: -1;
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  bottom: 3px;
  left: 0;
  background: var(--Brand-Green);
  border-radius: 30%
}

.userrevbox .userbox>ul {
  font-size: 0;
  margin-top: 35px
}

.userrevbox .userbox>ul>li {
  vertical-align: top;
  display: inline-block;
  width: 322px;
  height: 170px;
  border-radius: 5px;
  margin-right: 20px;
  box-shadow: 0 2px 20px rgba(126,172,207,.2);
  padding: 30px 30px 14px 30px;
  background: #fff;
  box-sizing: content-box;
}

.userrevbox .userbox>ul>li:last-child {
  margin-right: 0
}

.userrevbox .userbox>ul>li>div {
  margin-bottom: 16px
}

.userrevbox .userbox>ul>li>div>img {
  vertical-align: middle;
  margin-right: 18px;
  width: 48px;
  height: 48px;
  max-width: none;
  display: inline-block;
}

.userrevbox .userbox>ul>li>div>span {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle
}

.userrevbox .userbox>ul>li>p {
  font-size: 14px;
  color: #666;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 100px
}
</style>
