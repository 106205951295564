<script setup lang="ts">
import utils from './utils.ts'

// data数据
const state = reactive({
  list: [],
})

// 事件
const methods = {
  async getData() {
    const data = await http({
      url: '/pcapi/dest/list', // 请求api接口地址
      method: 'POST',
      body: {
        pageIndex: 1,
        pageSize: 3,
      },
    })
    state.list = (data || {}).data || []
  },
  jump(item) {
    item.citySEO = (item.citySEO && item.citySEO.toLowerCase()) || ''
    $utils.open(`${utils.iflightUrl(true)}/travelguide/${item.enName}-${item.id}.html`)
    $utils.sendEvent('', '', '目的地', `^${item.regionName}^`)
  },
  mouseover(item) {
    item.scale = true
  },
  mouseleave(item) {
    item.scale = false
  },
}

onMounted(() => {
  nextTick(() => {
    methods.getData()
  })
})
</script>

<template>
  <div v-if="state.list.length > 0" id="destibox">
    <div class="desti_content">
      <h3>{{ utils.tc('b_explore_title') }}</h3>
      <h4>{{ utils.tc('b_explore_subtitle') }}</h4>
      <ul class="destination">
        <template v-for="(item, index) in state.list" :key="item">
          <li v-if="index < 3">
            <a @click="methods.jump(item)">
              <div class="img_box">
                <img :src="item.coverPicUrl" :style="item.scale ? 'transform: scale(1.1, 1.1);' : ''" @mouseover="methods.mouseover(item)" @mouseleave="methods.mouseleave(item)">
              </div>
              <div class="info">
                <h5>{{ item.regionName }}</h5>
                <span />
                <p>{{ item.subTitle }}</p>
              </div>
            </a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#destibox {
  min-width: 1190px;
  max-width: 1920px;
  margin: 0 auto
}

#destibox .desti_content {
  width: 1190px;
  margin: 0 auto;
  padding-bottom: 50px
}

#destibox h3 {
  margin-top: -15px;
  text-align: center;
  font-size: 48px;
  font-weight: 500
}

#destibox h4 {
  text-align: center;
  font-size: 16px;
  color: #999;
  font-weight: 400;
  margin: 15px 0 40px 0
}

#destibox ul {
  font-size: 0;
  height: 536px
}

#destibox ul>li {
  vertical-align: top;
  display: inline-block;
  width: 380px;
  height: 536px;
  background: #fff;
  border-radius: 5px;
  margin-right: 23px;
  box-shadow: 0 2px 20px rgba(126,172,207,.2);
  cursor: pointer
}

#destibox ul>li:hover {
  box-shadow: 0 0 20px rgba(67,120,160,.2)
}

#destibox ul>li:last-child {
  margin-right: 0
}

#destibox ul>li .img_box {
  width: 380px;
  height: 360px;
  border-radius: 5px 5px 0 0;
  overflow: hidden
}

#destibox ul>li img {
  width: 380px;
  height: 360px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s
}

#destibox ul>li .info {
  text-align: center;
  padding: 28px 30px 36px 30px;
  font-size: 0
}

#destibox ul>li .info>h5 {
  font-size: 24px;
  font-weight: 700;
  color: #333
}

#destibox ul>li .info>span {
  display: inline-block;
  width: 20px;
  height: 3px;
  margin: 15px 0;
  background: var(--Brand-Green);
  border-radius: 30%
}

#destibox ul>li .info>p {
  font-size: 14px;
  color: #666;
  line-height: 25px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 47px
}
</style>
