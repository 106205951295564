<script setup>
import { useHotelStore } from '~/store/hotel'
import { jumpToRefidUrl } from '~/utils/refid'

const hotelStore = useHotelStore()
const cCity = computed(() => hotelStore.recCitys.cityList[hotelStore.recCitys.index])
const hotelList = computed(() => (cCity.value?.hotelList || []))
const data = reactive({
  x: 0,
  duration: 0,
})
const baseUrl = hotelStore.getBaseUrl()
const { t } = useLang()

function setTab(i) {
  hotelStore.recCitys.index = i
  const city = cCity.value
  if (city && city.cityId) {
    hotelStore.getRecHotels(city, i)
    data.x = 0
    data.duration = 0
  }
}

function prevPage() {
  const num = hotelList.value.length + 1 - 4
  data.x = 0
  data.duration = num * 300
}
function nextPage() {
  const num = hotelList.value.length + 1 - 4
  data.x = num * (284 + 18) // 宽 284 间距 18
  data.duration = num * 300
}

function getImg(n) {
  return (n.hotelHeadPicture || '').replace('/mobile220_220/', '/tHotel320_240/')
}
function getP2(n) {
  const a = n.recallInfoList?.find(n => n && n.type === 4)?.reasonName || ''
  const b = n.recallInfoList?.find(n => n && n.type === 5)?.reasonName || ''
  const c = (a && b) ? ' . ' : ''
  return a + c + b
}
function getHref(n) {
  const city = cCity.value
  const intl = hotelStore.getIntl(city) ? '&intl=1' : ''
  const refid = $utils.getRefid() || ''
  const target = jumpToRefidUrl(`${baseUrl}/hotel/hoteldetail?hotelid=${n.hotelId || ''}&inDate=${hotelStore.indate.value}&outDate=${hotelStore.outdate.value}${intl}&refid=${refid}`)
  return target
}

function getMoreHref() {
  const city = cCity.value
  const intl = hotelStore.getIntl(city) ? '&intl=1' : ''
  const refid = $utils.getRefid() || ''
  return `${baseUrl}/hotel/hotellist?city=${city.cityId}&inDate=${hotelStore.indate.value}&outDate=${hotelStore.outdate.value}${intl}&refid=${refid}`
}
</script>

<template>
  <div v-if="hotelStore.recCitys.cityList.length" class="rec_body">
    <div class="rec_title">
      {{ t('home.s_hotel_rec_title') }}
    </div>
    <div class="city_box PingFang_SC">
      <div v-for="(n, i) in hotelStore.recCitys.cityList" :key="`${i}${n.cityId}`" class="city_li" :class="{ city_li_select: i === hotelStore.recCitys.index }" @click="setTab(i)">
        {{ n.cityName }}
      </div>
    </div>
    <div v-if="cCity?.isok" class="res_box">
      <div class="res_ul" :style="{ left: `-${data.x}px`, transition: `left ${data.duration}ms` }">
        <a v-for="(n, i) in hotelList" :key="`${i}${n.hotelId}`" :href="getHref(n)" target="_blank" class="res_li" data-btn-type="pop-hotel">
          <div class="res_img_box">
            <img class="res_img" :src="getImg(n)" alt="">
            <div class="res_fen_box">
              <span class="res_fen">{{ n.commentScore }}</span>/5
            </div>
          </div>
          <div class="res_bottom PingFang_SC">
            <div class="res_p1">
              <div class="line-clamp-1"> {{ n.hotelName }}</div>
              <span class="inline-block ">
                <span v-for="m in n.starLevel" :key="m" class="inline-block star hotel_sprite_img" />
              </span>
            </div>
            <div class="res_p2 line-clamp-1">
              {{ getP2(n) }}
            </div>
            <div v-if="n.promotionSaleInfo?.desc" class="res_p3 line-clamp-1">
              {{ n.promotionSaleInfo?.desc }}
            </div>
            <div class="res_p5 line-clamp-1">
              <span v-if="n.price > 0" class="res_p5_s1">{{ n.afterPayment }}{{ n.price }}</span>
              <span v-else class="res_p5_s2">{{ t('home.s_hotel_rec_more_price0') }}</span>
            </div>
          </div>
        </a>
        <a class="res_li res_li_more" :href="getMoreHref()" target="_blank">
          <img class="res_li_more_img" src="https://file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/hotel/morehotel.jpg" alt="">
          <div class="more_box">
            <div class="more_title">
              {{ t('home.s_hotel_rec_more_title') }}
            </div>
            <div class="more_btn flex-center">
              {{ t('home.s_hotel_rec_more_btn') }}
            </div>
          </div>
        </a>
      </div>
    </div>
    <div v-else class="res_box">
      <div class="res_ul">
        <a v-for="i in 4" :key="`${i}`" class="res_li">
          <div class="res_img_box loading_bar" />
        </a>
      </div>
    </div>
    <div v-if="hotelList.length >= 4" class="res_btn_box">
      <div class="res_btn btn_prev hotel_sprite_img" @click="prevPage" />
      <div class="res_btn btn_next hotel_sprite_img" @click="nextPage" />
    </div>
  </div>
  <div v-else-if="!hotelStore.recCitys.cityList.isok" class="rec_body">
    <div class="rec_title">
      {{ t('home.s_hotel_rec_title') }}
    </div>
    <div class="city_box PingFang_SC">
      <div v-for="i in 5" :key="`${i}`" class="city_li loading_bar" />
    </div>
    <div class="res_box">
      <div class="res_ul">
        <a v-for="i in 4" :key="`${i}`" class="res_li">
          <div class="res_img_box loading_bar" />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading_bar {
  height: 100%;
  background: rgba(240 ,242 ,245, 1);
  animation: breathing 1.6s infinite ease-in-out;
}

@keyframes breathing {
    0% {
      background: rgba(240 ,242 ,245, 1);
    }

    50.0% {
      background: rgba(240 ,242 ,245, 0.3);
    }

    100.0% {
      background: rgba(240 ,242 ,245, 1);
    }
}

.rec_body {
  width: 1190px;
  margin: 0 auto;
  color: #333;
  cursor: default;
}

.rec_title {
  line-height: 1;
  text-align: center;
  padding-top: 50px;
  font-weight: 600;
  font-size: 48px;
}

.city_box {
  display: flex;
  padding: 24px 0 28px;
}

.city_li {
  flex: none;
  min-width: 120px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}

.city_li+.city_li {
  margin-left: 20px;
}

.city_li_select {
  font-weight: 600;
  background: var(--Brand-Green);
  color: #fff;
}

.res_box {
  position: relative;
  width: 100%;
  height: 420px;
  overflow: hidden;
}
.res_ul {
  position: relative;
  display: flex;
  transform: translate3d(0,0,0);
  transition: left 1600ms;
}
.res_li {
  flex: none;
  width: 284px;
  height: 380px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.res_li+.res_li {
  margin-left: 18px;
}

.res_img_box {
  position: relative;
  width: 100%;
  height: 180px;
}

.res_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.res_fen_box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  background: var(--Brand-Green);
  border-radius: 10px 0 0 0;
  color: #fff;
}
.res_fen {
  font-weight: 700;
  font-size: 24px;
}

.res_bottom {
  position: relative;
  height: 200px;
  padding: 20px 20px 10px;
}

.res_p1 {
  line-height: 28px;
  font-weight: 700;
  font-size: 20px;
}
.star {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  background-position: 0px -414px;
}

.res_p2 {
  margin-top: 5px;
  font-size: 16px;
  color: #999;
}
.res_p3 {
  margin-top: 5px;
  font-size: 13px;
  color: #ff0000;
}
.res_p4 {
  margin-top: 5px;
  font-size: 14px;
}
.res_p5 {
  position: absolute;
  bottom: 30px;
  left: 20px;
  right: 20px;
  margin-top: 5px;
}
.res_p5_s1 {
  font-weight: 700;
  font-size: 24px;
}
.res_p5_s2 {
  font-weight: 400;
  font-size: 24px;
  color: #999;
}
.res_btn_box {
  position: relative;
}
.res_btn {
  position: absolute;
  top: -280px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  background-position: 0px -452px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  cursor: pointer;
  stroke: #E4E4E4;
  filter: drop-shadow(0 4px 16px #0000001a);
}
.res_btn:hover {
  background-color: var(--Brand-Green);
}
.btn_prev {
  left: -38px;
  transform: rotate(180deg);
  &:hover{
    background-position: 0px -503px;
  }
}
.btn_next {
  right: -38px;
  &:hover{
    background-position: 0px -503px;
  }
}

.res_li_more {
  position: relative;
}
.res_li_more_img {
  display: block;
  width: 100%;
}
.more_box {
  position: absolute;
  bottom: 30px;
  left: 24px;
  right: 24px;
}
.more_title {
  font-weight: 600;
  font-size: 19px;
  color: #000;
  // text-align: center;
}
.more_btn {
  width: 180px;
  height: 50px;
  margin: 20px auto 0;
  border-radius: 6px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  background: var(--Brand-Green);
}
</style>
